import { throwAxiosException } from '@utils/exception';
import { instance } from './instance';
import { AxiosError } from 'axios';
import { PatchUser } from '@feature/auth/type/auth';
import { ChangePassword } from '@feature/user/type/users';

export class UserApi {
  /** [postSignup] 회원가입 */
  public static async postSignup(body: { user: Blob }) {
    return await instance
      .post(`/v1/users`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [getUsers] 마이페이지, 유저 로그인 여부 확인에 사용 */
  public static async getUsers() {
    return await instance
      .get(`/v1/users/my-info`)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [patchUsers] 마이페이지 수정 */
  public static async patchUsers(body: PatchUser) {
    return await instance
      .patch(`/v1/users/my-info`, body)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [deleteUsers] 계정 탈퇴 */
  public static async deleteUsers() {
    return await instance
      .delete(`/v1/users/my-info`)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [changePassword] 비밀번호 변경 */
  public static async changePassword(body: ChangePassword) {
    return await instance
      .patch(`/v1/users/me/password`, body)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }
}
