import { PatientResponse } from '@feature/patient/type/patient';
import PatientsList from './PatientsList';
import styled from 'styled-components';
import { themeColor } from '@utils/theme';

type Props = {
  patientData: PatientResponse[];
  patientId: string;
  selectedIndex: number;
  moveFn: (url: string) => void;
};

const SamePatientList = ({
  patientData,
  patientId,
  selectedIndex,
  moveFn,
}: Props) => {
  return (
    <>
      {patientData.map(
        (value, index) =>
          value.patient_identifier.toUpperCase() ===
            patientId.toUpperCase() && (
            <SamePatient key={index}>
              <PatientsList
                data={[value]}
                name="same"
                patientId={patientId}
                selectedIndex={selectedIndex}
                moveFn={moveFn}
              />
            </SamePatient>
          ),
      )}
    </>
  );
};

export default SamePatientList;

const SamePatient = styled.div`
  color: ${themeColor.green_500};
`;
