import { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import ErrorComponent from '@components/error/ErrorComponent';
import ErrorPage from '@components/error/ErrorPage';
import Layout from './layout/Layout';
import InnerLayout from './layout/InnerLayout';
import CommunicationLayout from './layout/CommuicationLayout';
import PatientLayout from './layout/PatientLayout';

const Home = lazy(() => import('@app/routes/Home'));
const Landing = lazy(() => import('@app/routes/Landing'));
const Communication = lazy(() => import('@app/routes/Communication'));
const Patient = lazy(() => import('@app/routes/Patient'));
const Exam = lazy(() => import('@app/routes/Exam'));
const Signup = lazy(() => import('@app/routes/Signup'));
// const Crt = lazy(() => import('@ui/pages/Crt'));
const Signin = lazy(() => import('@app/routes/Signin'));
const Account = lazy(() => import('@app/routes/Account'));
const SelectSignup = lazy(() => import('@app/routes/SelectSignup'));
const ForgotPassword = lazy(() => import('@app/routes/ForgotPassword'));
const Demo = lazy(() => import('@app/routes/Demo'));

// [배포]
const signupPage =
  import.meta.env.MODE === 'production'
    ? []
    : [
        {
          path: '/signup',
          element: (
            <Layout>
              <InnerLayout color="pale">
                <Suspense fallback={<></>}>
                  <SelectSignup />
                </Suspense>
              </InnerLayout>
            </Layout>
          ),
        },
      ];
// [배포]
const selectSignupPage =
  import.meta.env.MODE === 'production'
    ? []
    : [
        {
          path: '/signup/:user_type',
          element: (
            <Layout>
              <InnerLayout color="pale">
                <Suspense fallback={<></>}>
                  <Signup />
                </Suspense>
              </InnerLayout>
            </Layout>
          ),
        },
      ];

const router = createBrowserRouter(
  [
    {
      path: '/',
      errorElement: (
        <Layout>
          <InnerLayout color="white">
            <ErrorComponent type="404" />
          </InnerLayout>
        </Layout>
      ),
      element: (
        <Layout>
          <InnerLayout color="white">
            <Suspense fallback={<></>}>
              <Home />
            </Suspense>
          </InnerLayout>
        </Layout>
      ),
    },
    {
      path: '/landing',
      element: (
        <Layout>
          <Suspense fallback={<></>}>
            <Landing />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: '/communication/:patient_uuid',
      element: (
        <Layout>
          <CommunicationLayout>
            <Suspense fallback={<></>}>
              <Communication />
            </Suspense>
          </CommunicationLayout>
        </Layout>
      ),
      errorElement: (
        <ErrorPage>
          <ErrorComponent type="ERROR" />
        </ErrorPage>
      ),
    },
    {
      path: '/signin',
      element: (
        <Layout>
          <InnerLayout color="pale">
            <Suspense fallback={<></>}>
              <Signin />
            </Suspense>
          </InnerLayout>
        </Layout>
      ),
    },
    ...signupPage,
    ...selectSignupPage,
    {
      path: '/patient/:patient_uuid',
      element: (
        <Layout>
          <PatientLayout>
            <Suspense fallback={<></>}>
              <Patient />
            </Suspense>
          </PatientLayout>
        </Layout>
      ),
      errorElement: (
        <ErrorPage>
          <ErrorComponent type="ERROR" />
        </ErrorPage>
      ),
    },
    {
      path: '/password-reset',
      element: (
        <Layout>
          <InnerLayout color="pale">
            <Suspense fallback={<></>}>
              <ForgotPassword />
            </Suspense>
          </InnerLayout>
        </Layout>
      ),
      errorElement: (
        <ErrorPage>
          <ErrorComponent type="ERROR" />
        </ErrorPage>
      ),
    },
    {
      path: '/exam/:patient_uuid',
      element: (
        <Layout>
          <PatientLayout>
            <Suspense fallback={<></>}>
              <Exam />
            </Suspense>
          </PatientLayout>
        </Layout>
      ),
      errorElement: (
        <ErrorPage>
          <ErrorComponent type="ERROR" />
        </ErrorPage>
      ),
    },
    {
      path: '/account',
      element: (
        <Layout>
          <InnerLayout color="pale">
            <Suspense fallback={<></>}>
              <Account />
            </Suspense>
          </InnerLayout>
        </Layout>
      ),
    },
    {
      path: '/demo',
      element: (
        <Layout>
          <InnerLayout color="pale">
            <Suspense fallback={<></>}>
              <Demo />
            </Suspense>
          </InnerLayout>
        </Layout>
      ),
      errorElement: (
        <ErrorPage>
          <ErrorComponent type="ERROR" />
        </ErrorPage>
      ),
    },
    // {
    //   path: '/crt/:patient_uuid',
    //   element: (
    //     <PatientLayout>
    //       <Suspense fallback={<></>}>
    //         <Crt />
    //       </Suspense>
    //     </PatientLayout>
    //   ),
    //   errorElement: <ErrorComponent type="ERROR" />,
    // },
    {
      path: '/404',
      element: (
        <Layout>
          <InnerLayout color="white">
            <ErrorComponent type="404" />
          </InnerLayout>
        </Layout>
      ),
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
    },
  },
);

export default router;
