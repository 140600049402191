export const statusCodeConfig = [
  {
    code: 401,
    excludePaths: ['/signin', '/signup', '/passsword-reset', '/demo'],
    redirectTo: '/signin',
  },
  {
    code: 403,
    excludePaths: ['/'],
    redirectTo: '/',
  },
  {
    code: 404,
    specificUrl: '/users/my-info',
    excludePaths: ['/signin'],
    redirectTo: '/signin',
  },
];
