import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useSetAtom } from 'jotai';
import { useQuery } from '@tanstack/react-query';
import { Email, GetUser } from '@feature/auth/type/auth';
import useUsers from '../../hooks/useUsers';
import { ModalStateAtom } from '@utils/modalAtom';
import { Response } from '@type/response';
import Modal from '@components/Modal';
import Input from '@components/Input';
import {
  defaultFont,
  flexStyle,
  fontStyle,
  sizeStyle,
} from '@utils/commonStyle';
import { themeColor } from '@utils/theme';

const DeleteUserModal = () => {
  const { register, watch } = useForm<Email>();
  const users = useUsers();
  const setModalState = useSetAtom(ModalStateAtom);

  const { data } = useQuery<Response<GetUser>>({
    queryKey: ['getUsers'],
  });

  const deleteAccount = () => {
    users.deleteHandler();
    setModalState('none');
  };

  const cancel = () => {
    setModalState('none');
  };

  const isDisabled = () => {
    if (watch('email') === data?.data?.email) return false;
    else return true;
  };

  return (
    <Container>
      <Modal.Title>Account Deletion Confirmation</Modal.Title>
      <Modal.Body>
        <Content>
          <Explanation>
            You are about to delete the following account:
            <br />
            <Emphasize>{data?.data?.email}</Emphasize>
            <br />
            This action is irreversible.
            <br />
            To confirm, re-enter your email:
          </Explanation>
          <Item>
            <label>Email</label>
            <Input type="email" {...register('email')} />
          </Item>
        </Content>
      </Modal.Body>
      <Modal.Buttons style={{ gap: '50px' }}>
        <CustomButton
          $color="red"
          disabled={isDisabled()}
          onClick={deleteAccount}>
          Delete Account
        </CustomButton>
        <CustomButton $color="light" onClick={cancel}>
          Cancel
        </CustomButton>
      </Modal.Buttons>
    </Container>
  );
};

export default DeleteUserModal;

const Container = styled.div`
  width: 600px;
`;
const Content = styled.div`
  ${flexStyle('column')('20px')()()}
  padding-top : 40px;
`;
const Explanation = styled.div`
  color: ${themeColor.green_500};
`;
const Emphasize = styled.span`
  color: ${themeColor.red};
  font-weight: 500;
`;
const Item = styled.div`
  ${flexStyle('column')('4px')()()}
  ${fontStyle('12px')(400)(20)};
  letter-spacing: -0.25px;
  color: ${themeColor.green_700};
`;
const CustomButton = styled.button<{ $color: 'red' | 'light' }>`
  ${defaultFont}
  ${fontStyle('16px')(500)(24)};
  ${sizeStyle('180px')('40px')};
  ${flexStyle()('8px')('center')('center')};

  background: ${props => (props.$color === 'red' ? themeColor.red : 'white')};
  color: ${props => (props.$color === 'red' ? 'white' : themeColor.green_600)};
  border: ${props =>
    props.$color === 'light' ? `2px solid ${themeColor.green_600}` : 'none'};
  border-radius: 9999px;
  box-sizing: border-box;
  cursor: pointer;

  &:disabled {
    background: ${themeColor.green_200};
  }
`;
