import styled from 'styled-components';
import loocus_logo from '@assets/images/loocus_myopia_logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { flexStyle, sizeStyle } from '@utils/commonStyle';
import SearchBox from '@feature/patient/components/searchBox/SearchBox';
import HeaderDrop from './HeaderDrop';

type Props = {
  hasSearchBox: boolean;
};

const Header = ({ hasSearchBox }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogoClick = () => {
    const isDemoPage = location.pathname === '/demo';
    navigate(isDemoPage ? '/signin' : '/');
  };

  return (
    <Container>
      <Content>
        <LeftSection>
          <img
            src={loocus_logo}
            width="185"
            height="20"
            alt="logo"
            onClick={handleLogoClick}
            style={{ cursor: 'pointer' }}
          />
        </LeftSection>
        {hasSearchBox && <SearchBox page="header" />}
        <RightSection>
          <HeaderDrop />
        </RightSection>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  background: rgba(245, 248, 253, 0.86);
  border-bottom: 1px solid #dfe4e7;
  ${sizeStyle('100%')('78px')}
  min-width : 1400px;
  ${flexStyle()()('center')('center')};
`;
const Content = styled.div`
  ${flexStyle()()('space-between')('center')};
  ${sizeStyle('1400px')()};
`;
const LeftSection = styled.div``;
const RightSection = styled.div``;

export default Header;
