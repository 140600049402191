import { defaultFont, flexStyle, fontStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';
import styled from 'styled-components';

interface Props {
  type: '404' | 'ERROR';
}

const ErrorText = ({ type }: Props) => {
  return (
    <TextSection>
      <Left>{type}</Left>
      <Right className="typo-body-lg">
        This is a temporary error.
        <br />
        Please try again later.
      </Right>
    </TextSection>
  );
};
export default ErrorText;

const TextSection = styled.div`
  ${flexStyle()()()('center')}
  color: ${themeColor.green_400};
`;
const Left = styled.div`
  ${fontStyle('60px')(700)(72)}
  ${defaultFont}

  padding: 0px 20px;
  border-right: 1px solid ${themeColor.green_400};
`;
const Right = styled.div`
  padding: 0px 20px;
`;
