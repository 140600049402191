import { PatientResponse } from '@feature/patient/type/patient';
import { sizeStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';
import styled from 'styled-components';
import PatientsList from './PatientsList';

type Props = {
  recentData: PatientResponse[];
  patientId: string;
  selectedIndex: number;
  moveFn: (url: string) => void;
};

const RecentlyPatientList = ({
  recentData,
  patientId,
  selectedIndex,
  moveFn,
}: Props) => {
  return (
    <div>
      <RecentlyTitle>Recently viewed</RecentlyTitle>
      <PatientsList
        data={recentData}
        name="recently"
        patientId={patientId}
        selectedIndex={selectedIndex}
        moveFn={moveFn}
      />
    </div>
  );
};

export default RecentlyPatientList;

const RecentlyTitle = styled.div`
  ${sizeStyle('90%')()}
  margin: 0 auto;
  padding: 14px 0px 14px 30px;
  text-align: start;
  font-size: 14px;
  border-bottom: 1.5px solid ${themeColor.green_200};
  color: ${themeColor.green_500};
`;
