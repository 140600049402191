import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useSetAtom } from 'jotai';
import useToast from '@hooks/useToast';
import ErrorIcon from '@assets/icons/common/error.svg';
import { ModalStateAtom } from '@utils/modalAtom';
import { Email } from '@feature/auth/type/auth';
import { subModalDataAtom, subModalEnabledAtom } from '@utils/subModalAtom';
import { AuthApi } from '@service/authApi';
import { confirmSubmodalDescription } from '@utils/subModalDescription';
import Modal from '@components/Modal';
import Input from '@components/Input';
import { flexStyle, fontStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';

const ForgotPasswordModal = () => {
  const setModalState = useSetAtom(ModalStateAtom);
  const { register, getValues } = useForm<Email>();
  const { pushToast } = useToast();
  const setSubModalEnabled = useSetAtom(subModalEnabledAtom);
  const setSubModalData = useSetAtom(subModalDataAtom);

  const cancel = () => {
    setModalState('none');
  };

  const resetPassword = async () => {
    pushToast('Email is being sent.', 'info');
    try {
      const data = getValues();
      await AuthApi.postPasswordResetRequest(data);
      pushToast('Verification email sent. Please check your inbox.', 'success');
      setModalState('none');
    } catch (err) {
      setSubModalData({
        type: 'error',
        icon: <img src={ErrorIcon} alt="error icon" width="48" height="48" />,
        title: 'Email Sending Failed',
        description: confirmSubmodalDescription('emailFailed'),
        yes: () => {
          setSubModalEnabled(false);
        },
      });
      setSubModalEnabled(true);
    }
  };

  return (
    <div>
      <Modal.Title>Forgot Password</Modal.Title>
      <Modal.Body>
        <Wrapper>
          <Description className="typo-body-lg">
            Please enter your email to reset your password.
          </Description>
          <Item>
            <label>Email</label>
            <Input type="email" {...register('email')} />
          </Item>
        </Wrapper>
      </Modal.Body>
      <Modal.Buttons style={{ gap: '50px' }}>
        <CustomButton $color="light" onClick={cancel}>
          Cancel
        </CustomButton>
        <CustomButton $color="dark" onClick={resetPassword}>
          Reset Password
        </CustomButton>
      </Modal.Buttons>
    </div>
  );
};

const Wrapper = styled.div`
  ${flexStyle('column')('20px')()()}
  padding-top : 40px;
  width: 520px;
`;
const Description = styled.div`
  color: ${themeColor.green_600};
`;

const Item = styled.div`
  ${flexStyle('column')('4px')()()}
  ${fontStyle('12px')(400)(20)};
  letter-spacing: -0.25px;
  color: ${themeColor.green_700};
`;
const CustomButton = styled.button<{ $color: 'dark' | 'light' }>`
  ${fontStyle('16px')(500)(24)};
  letter-spacing: -0.25px;
  ${flexStyle()('8px')('center')('center')};
  box-sizing: border-box;
  padding: 10px;

  width: 180px;
  height: 40px;

  background: ${props =>
    props.$color === 'dark' ? themeColor.green_600 : 'white'};
  color: ${props => (props.$color === 'dark' ? 'white' : themeColor.green_600)};
  border: 2px solid ${themeColor.green_600};
  border-radius: 9999px;

  &:hover {
    cursor: pointer;
  }
`;

export default ForgotPasswordModal;
