import styled from 'styled-components';
import edit_icon from '@assets/icons/common/edit.svg';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ModalStateAtom } from '@utils/modalAtom';
import { Response } from '@type/response';
import { ExamsResponse } from '@feature/exam/type/exams';
import { ExamsApi } from '@service/examsApi';
import { PatientApi } from '@service/patientApi';
import PatientInfoData from './PatientInfoData';
import { flexStyle, sizeStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';
import { PatientResponse } from '../../type/patient';
import PatientInfoDate from './PatientInfoDate';
import { exceptionMessage } from '@utils/exception';

const PatientInfo = () => {
  const setModalState = useSetAtom(ModalStateAtom);

  const { patient_uuid } = useParams();
  if (!patient_uuid) throw new Error(exceptionMessage.NoUuid);

  const queryClient = useQueryClient();

  const patientExams = useQuery<Response<ExamsResponse>>({
    queryKey: ['getLatestExams', patient_uuid],
    queryFn: () => ExamsApi.getLatestPatientExamsCreated(patient_uuid),
    enabled: patient_uuid !== undefined,
  });
  const patientInfo = useQuery<Response<PatientResponse>>({
    queryKey: ['getPatient', patient_uuid],
    queryFn: () => PatientApi.getPateintsByPatientUuid(patient_uuid),
  });

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['getRecentPatient', 3],
    });
  }, [patientInfo.data]);

  const recentDate = patientExams.data?.data;
  const patientData = patientInfo.data?.data;

  if (patientExams.error) throw patientExams.error;
  else if (patientInfo.error) throw patientInfo.error;
  else if (!patientData) return <Container />;

  const renderDate = recentDate ? recentDate?.exam_date : 'null';

  return (
    <Container>
      <Content>
        <EditSection className="typo-button-md">
          <EditButton
            data-testid="edit-patient-modal-buttons"
            onClick={() => {
              setModalState('editPatient');
            }}>
            <img src={edit_icon} width="12" height="12" alt="patient edit" />
            EDIT
          </EditButton>
        </EditSection>
        <Patient>
          <PatientInfoDate date={renderDate}>Recent Exam Date</PatientInfoDate>
          <PatientInfoData data={patientData} />
        </Patient>
      </Content>
    </Container>
  );
};

export default PatientInfo;

const Container = styled.div`
  ${sizeStyle('1400px')('370px')}
  ${flexStyle()()('center')('center')}
  margin: auto;
`;
const Content = styled.div`
  width: 100%;
`;
const EditSection = styled.div`
  ${flexStyle()()('right')()}
`;
const EditButton = styled.div`
  cursor: pointer;
  ${flexStyle()('6px')()('center')}
  color: ${themeColor.green_600};
`;
const Patient = styled.div`
  ${flexStyle()()('space-between')()}
`;
