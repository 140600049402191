import { Fragment } from 'react';
import { PatientResponse } from '../../type/patient';
import PatientListItem from './PatientListItem';

type Props = {
  data: PatientResponse[];
  name: 'same' | 'similar' | 'recently';
  patientId: string;
  selectedIndex: number;
  moveFn: (url: string) => void;
};

const PatientsList = ({
  data,
  name,
  patientId,
  selectedIndex,
  moveFn,
}: Props) => {
  return (
    <>
      {Object.values(data).map((ele, index) => {
        if (patientId === ele.patient_identifier && name === 'similar')
          return <Fragment key={index} />;

        return (
          <PatientListItem
            key={index}
            patient={ele}
            patientId={patientId}
            name={name}
            selectedIndex={selectedIndex}
            index={index}
            moveFn={moveFn}
          />
        );
      })}
    </>
  );
};

export default PatientsList;
