export const PatientListBackground = (
  name: string,
  selectedIndex: number,
  index: number,
): string => {
  const samePatient = name === 'same' && selectedIndex === -1;
  const simRecPatient =
    (name === 'similar' || name === 'recently') && selectedIndex === index;
  if (samePatient || simRecPatient) return '#f5f8fd';
  else return 'white';
};
