import { Exams } from '@feature/exam/type/exams';
import { instance } from './instance';
import { AxiosError } from 'axios';
import { throwAxiosException } from '@utils/exception';
import { EyeSide } from '@type/fundus';

export class ExamsApi {
  /** [postExams] 환자 Exam 생성*/
  public static async postPatientsExams(patient_uuid: string, body: Exams) {
    return await instance
      .post(`/v2/exams?patient_uuid=${patient_uuid}`, body)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [getExams] 환자 Exam 목록 조회 (페이지네이션) */
  public static async getPatientsExamsByPatientUuid(
    patient_uuid: string,
    page: number,
    size: number,
  ) {
    return await instance
      .get(`/v2/exams?patient_uuid=${patient_uuid}&page=${page}&size=${size}`)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [getExamsOne] 환자 검사 조회 */
  public static async getExamsOne(exam_uuid: string) {
    return await instance
      .get(`/v2/exams/${exam_uuid}`)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [getLatestExams] 환자의 최근 생성된 Exam 조회 */
  public static async getLatestPatientExamsCreated(
    patient_uuid: string,
    eye_side?: EyeSide,
  ) {
    const eyeSide = eye_side ? `&eye_side=${eye_side.toLocaleUpperCase()}` : '';
    return await instance
      .get(`/v2/exams/latest?patient_uuid=${patient_uuid}${eyeSide}`)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [patchExams] Exam 수정 */
  public static async patchPatientExams(exam_uuid: string, body: Exams) {
    return await instance
      .patch(`/v2/exams/${exam_uuid}`, body)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [deleteExams] Exam삭제 */
  public static async deletePatientExamsByExamUuid(exam_uuid: string) {
    return await instance
      .delete(`/v1/exams/${exam_uuid}`)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [getHospital] 병원 데이터 불러오기 */
  public static async getExamsHospital(patient_identifier: string) {
    return await instance
      .get(`/v1/exams/hospital?patient_identifier=${patient_identifier}`)
      .then(rest => rest.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [getExamsAnalytics] 병원 데이터 불러오기 */
  public static async getExamsAnalytics(patient_uuid: string) {
    return await instance
      .get(`/v1/exams/latest/analytics?patient_uuid=${patient_uuid}`)
      .then(rest => rest.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }
}
