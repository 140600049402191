import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { PatientResponse } from '../../type/patient';
import { GetUser } from '@feature/auth/type/auth';
import { Response } from '@type/response';
import PatientBox from './PatientBox';
import { sizeStyle } from '@utils/commonStyle';
import { exceptionMessage } from '@utils/exception';
import { extractedData } from './utils';

type Props = {
  data: PatientResponse;
};

const PatientInfoData = ({ data }: Props) => {
  const { patient_uuid } = useParams();
  if (!patient_uuid) throw new Error(exceptionMessage.NoUuid);

  const users = useQuery<Response<GetUser>>({
    queryKey: ['getUsers'],
  });
  const isInstitution = users.data?.data?.account_type === 'INSTITUTION';

  return (
    <Container>
      <PatientBox data={extractedData(data, 'patient')} dataType="patient">
        Patient Info.
      </PatientBox>
      {isInstitution && (
        <PatientBox data={extractedData(data, 'doctor')} dataType="doctor">
          Doctor Info.
        </PatientBox>
      )}
    </Container>
  );
};

export default PatientInfoData;

const Container = styled.div`
  ${sizeStyle('82%')('100%')}
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
`;
