import { PatientResponse } from '@feature/patient/type/patient';
import PatientsList from './PatientsList';

type Props = {
  patientData: PatientResponse[];
  patientId: string;
  selectedIndex: number;
  moveFn: (url: string) => void;
};

const SimilarPatientList = ({
  patientData,
  patientId,
  selectedIndex,
  moveFn,
}: Props) => {
  return (
    <div>
      <PatientsList
        data={patientData}
        name="similar"
        patientId={patientId}
        selectedIndex={selectedIndex}
        moveFn={moveFn}
      />
    </div>
  );
};

export default SimilarPatientList;
