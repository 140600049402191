import Modal from '@components/Modal';
import SubModal from '@components/SubModal';
import Toast from '@components/Toast';

const Layout = ({ children }: { children: JSX.Element }) => (
  <>
    <Modal />
    <SubModal />
    <Toast />
    {children}
  </>
);

export default Layout;
