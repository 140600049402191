import {
  Doctors,
  GetUser,
  PatchUser,
  UserForm,
  userFormschema,
} from '@feature/auth/type/auth';
import { FieldPath, UseFormReturn, useForm } from 'react-hook-form';
import { ZodError } from 'zod';

interface UseUsersLogic {
  methods: UseFormReturn<UserForm>;
  setUsers: (data: GetUser) => void;
  validate: (data: UserForm) => void;
  validateItem: (item: FieldPath<UserForm>) => boolean;
  formToBody: (data: UserForm) => PatchUser;
}

const useUsersLogic = (): UseUsersLogic => {
  const methods = useForm<UserForm>();

  const setUsers = (data: GetUser) => {
    const { user_uuid, ...rest } = data;
    void user_uuid;
    methods.reset({ ...rest });
  };

  const validate = (data: UserForm) => {
    userFormschema.parse(data);
  };

  const validateItem = (item: FieldPath<UserForm>) => {
    if (!methods.watch(item)) return false;
    try {
      userFormschema.parse(methods.watch());
      return false;
    } catch (err) {
      if (
        err instanceof ZodError &&
        err.errors.filter(error => item === error.path.join('.')).length > 0
      )
        return true;
      return false;
    }
  };

  const formToBody = (data: UserForm) => {
    const { email, doctors, ...rest } = data;
    void email;
    const filteredDoctor: Doctors | undefined =
      doctors?.length && doctors.length > 0 ? doctors : undefined;
    return { ...rest, doctors: filteredDoctor };
  };

  return { methods, setUsers, validate, validateItem, formToBody };
};

export default useUsersLogic;
