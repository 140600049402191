import { flexStyle, sizeStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';
import styled from 'styled-components';

type Props = {
  selectedIndex: number;
  patientId: string;
  addFn: () => void;
};

const AddPatientList = ({ selectedIndex, patientId, addFn }: Props) => {
  return (
    <AddPatient $selectedIndex={selectedIndex}>
      <AddIdSpan>No ID search results.</AddIdSpan>
      <AddIdSpan>
        +Add a new patient with ID
        <AddIdBtn
          data-testid="open-add-patient-modal-button"
          onClick={() => {
            addFn();
          }}>
          {patientId}
        </AddIdBtn>
      </AddIdSpan>
    </AddPatient>
  );
};

export default AddPatientList;

const AddPatient = styled.div<{ $selectedIndex: number }>`
  ${sizeStyle('100%')('40px')}
  padding: 10px 62px 10px 50px;
  box-sizing: border-box;
  background: ${props => (props.$selectedIndex === -1 ? '#f5f8fd' : 'white')};
  ${flexStyle()()('space-between')()};
`;
const AddIdSpan = styled.span`
  color: ${themeColor.green_500};
  font-size: 14px;
  ${flexStyle()('10px')()('center')}
`;
const AddIdBtn = styled.button`
  ${sizeStyle('124px')('20px')}
  border-radius: 9999px;
  border: 1px solid #4fb7d0;
  color: ${themeColor.green_400};
  font-size: 12px;
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;
