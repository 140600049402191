import { throwAxiosException } from '@utils/exception';
import { instance } from './instance';
import { AxiosError } from 'axios';
import { Email, EmailConfirm, SigninType } from '@feature/auth/type/auth';

export class AuthApi {
  /** [postSignin] 로그인 */
  public static async postSignin(body: SigninType) {
    return await instance
      .post(`/v1/auth/signin`, body)
      .then(res => {
        localStorage.setItem('Session-Id', res.headers['session-id']);
      })
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }
  /** [postSignout] 로그아웃 */
  public static async postSignout() {
    return await instance
      .post(`/v1/auth/signout`)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }
  /** [postEmailVerifyRequest] 회원가입 시 메일 인증 요청 */
  public static async postEmailVerificationRequest(body: Email) {
    return await instance
      .post(`/v1/auth/email-verification/request`, body)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }
  /** [postEmailVerifyConfirm] 회원가입 시 메일 인증 코드 인증 요청 */
  public static async postEmailVerificationConfirm(body: EmailConfirm) {
    return await instance
      .post(`/v1/auth/email-verification/confirm`, body)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }
  /** [postPasswordResetRequest] 비밀번호 초기화 메일 발송 요청 */
  public static async postPasswordResetRequest(body: Email) {
    return await instance
      .post(`/v1/auth/password-reset/request`, body)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }
  /** [postPasswordResetConfirm] 초기화 할 새 비밀번호 요청 */
  public static async postPasswordResetConfirm(body: {
    reset_code: string;
    password: string;
  }) {
    return await instance
      .post(`/v1/auth/password-reset/confirm`, body)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }
  /** [postPasswordResetCheck] 비밀번호 초기화 메일 링크의 유효함을 판단하기 위한 요청 */
  public static async postPasswordResetCheck(email_code: string) {
    return await instance
      .post(`/v1/auth/password-reset/check`, { email_code: email_code })
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** 테스트 데이터 */
  public static async authTestVerification(body: Email) {
    return await instance
      .post(`/v1/auth/email-verification/mock`, body)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }
}
