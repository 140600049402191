import { flexStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';
import styled from 'styled-components';

const CommunicationLayout = ({ children }: { children: JSX.Element }) => (
  <Container $color="white">
    <Content>{children}</Content>
  </Container>
);

const Container = styled.div<{ $color: 'white' | 'pale' }>`
  ${flexStyle('column')()()()}
  min-height: 100vh;
  position: relative;
  background-color: ${props =>
    props.$color === 'white' ? 'white' : themeColor.blue_bg};
`;
const Content = styled.div`
  flex: 1;
`;

export default CommunicationLayout;
