import { FieldPath, UseFormReturn } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useUsersLogic from './useUsersLogic';
import { useSetAtom } from 'jotai';
import SuccessIcon from '@assets/icons/common/success.svg';
import ErrorIcon from '@assets/icons/common/error.svg';
import { GetUser, UserForm } from '@feature/auth/type/auth';
import useToast from '@hooks/useToast';
import { subModalDataAtom, subModalEnabledAtom } from '@utils/subModalAtom';
import { confirmSubmodalDescription } from '@utils/subModalDescription';
import { UserApi } from '@service/userApi';

export interface UseUsers {
  methods: UseFormReturn<UserForm>;
  saveHandler: () => Promise<void>;
  deleteHandler: () => Promise<void>;
  setUsers: (data: GetUser) => void;
  isDisabled: () => boolean;
  validateItem: (item: FieldPath<UserForm>) => boolean;
}

const useUsers = (): UseUsers => {
  const { methods, setUsers, validate, validateItem, formToBody } =
    useUsersLogic();
  const { pushToast } = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setSubModalEnabled = useSetAtom(subModalEnabledAtom);
  const setSubModalData = useSetAtom(subModalDataAtom);

  const deleteUserSuccess = () => {
    setSubModalData({
      type: 'success',
      icon: <img src={SuccessIcon} alt="success icon" width="48" height="48" />,
      title: 'Account Deleted',
      description: confirmSubmodalDescription('userSecessionSuccess'),
      yes: () => {
        setSubModalEnabled(false);
      },
    });
    setSubModalEnabled(true);
  };
  const deleteUserFailed = () => {
    setSubModalData({
      type: 'error',
      icon: <img src={ErrorIcon} alt="error icon" width="48" height="48" />,
      title: 'Account Deletion Failed',
      description: confirmSubmodalDescription('userSecessionFailed'),
      yes: () => {
        setSubModalEnabled(false);
      },
    });
    setSubModalEnabled(true);
  };

  const saveHandler = async () => {
    try {
      const data = methods.getValues();

      await UserApi.patchUsers(formToBody(data));
      pushToast('Account information updated successfully', 'success');
      queryClient.invalidateQueries({
        queryKey: ['getUsers'],
      });
    } catch (err) {
      pushToast(
        'Failed to update account information. Please try again.',
        'error',
      );
    }
  };
  const deleteHandler = async () => {
    try {
      await UserApi.deleteUsers();

      queryClient.removeQueries();
      localStorage.clear();
      deleteUserSuccess();

      navigate('/signin');
    } catch (err) {
      deleteUserFailed();
    }
  };

  const isDisabled = () => {
    try {
      validate(methods.watch());
      return false;
    } catch (err) {
      return true;
    }
  };

  return {
    methods,
    saveHandler,
    deleteHandler,
    setUsers,
    isDisabled,
    validateItem,
  };
};

export default useUsers;
