import {
  ForwardedRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  forwardRef,
} from 'react';
import styled from 'styled-components';
import {
  defaultFont,
  disabledStyle,
  errorStyle,
  flexStyle,
  fontStyle,
  sizeStyle,
} from '@utils/commonStyle';
import { themeColor } from '@utils/theme';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  type: HTMLInputTypeAttribute;
  error?: boolean;
  disabled?: boolean;
  unit?: string;
}

const Input = forwardRef(
  (
    { type, unit, error = false, disabled = false, ...rest }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <InputContainer $error={error} $disabled={disabled} $type={type}>
        <input type={type} ref={ref} {...rest} disabled={disabled} />
        {unit && <div>{unit}</div>}
      </InputContainer>
    );
  },
);

const InputContainer = styled.div<{
  $error: boolean;
  $disabled: boolean;
  $type: HTMLInputTypeAttribute;
}>`
  padding: 8px 10px;
  background: white;
  border: 1px solid #e1e6ed;
  box-sizing: border-box;
  input {
    ${defaultFont};
    ${fontStyle('14px')(600)(20)};
    border: none;
    text-align: ${props => (props.$type === 'number' ? 'right' : 'left')};
    background-color: rgba(0, 0, 0, 0);
    color: ${themeColor.green_700};
    width: 100%;

    &::placeholder {
      color: #d0cece;
      text-align: ${props => (props.$type === 'number' ? 'right' : 'left')};
      font-weight: 400;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: #000;
      -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
      box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
  div {
    ${fontStyle('12px')(400)(20)};
    ${defaultFont};
    text-align: right;
    color: ${themeColor.green_400};
  }

  ${sizeStyle('100%')('100%')};
  ${flexStyle()('8px')()('center')}
  ${props => props.$disabled && disabledStyle}
  ${props => props.$error && errorStyle}
`;

Input.displayName = 'Input';
export default Input;
