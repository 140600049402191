import { useAtom } from 'jotai';
import styled from 'styled-components';
import React, { Suspense, lazy } from 'react';
import {
  flexStyle,
  fontStyle,
  positionStyle,
  sizeStyle,
} from '@utils/commonStyle';
import { themeColor } from '@utils/theme';
import { ModalStateAtom } from '@utils/modalAtom';
import ForgotPasswordModal from '@feature/user/components/forgotPasswordModal/ForgotPasswordModal';
import DeleteUserModal from '@feature/user/components/deleteUserModal/DeleteUserModal';
import ChangePasswordModal from '@feature/user/components/changePasswordModal/ChangePasswordModal';
import Portal from './Portal';
import ErrorBoundary from './error/ErrorBoundary';

const PatientModal = lazy(
  () => import('@feature/patient/components/patientModal/PatientModal'),
);
const ExamsModal = lazy(
  () => import('@feature/exam/components/examsModal/ExamsModal'),
);
const TreatmentModal = lazy(
  () => import('@feature/treatment/components/treatmentModal/TreatmentModal'),
);
const ChartSettingModal = lazy(
  () => import('@feature/chart/components/chartSettingModal/ChartSettingModal'),
);

interface IModal {
  (): JSX.Element;
  Title: React.FC<Props & { color?: string }>;
  Body: React.FC<Props>;
  Content: React.FC<Props>;
  Buttons: React.FC<Props>;
}
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const Modal: IModal = () => {
  const [modalState, setModalState] = useAtom(ModalStateAtom);
  /** 아래와 같이 세부 모달을 추가해주세요 */
  const selectModal = () => {
    switch (modalState) {
      case 'addExams':
        return <ExamsModal type="add" />;
      case 'editExams':
        return <ExamsModal type="edit" />;
      case 'addPatient':
        return <PatientModal type="add" />;
      case 'editPatient':
        return <PatientModal type="edit" />;
      case 'chartSetting':
        return <ChartSettingModal />;
      case 'startTreatment':
        return <TreatmentModal modalState="startTreatment" />;
      case 'endTreatment':
        return <TreatmentModal modalState="endTreatment" />;
      case 'changeTreatment':
        return <TreatmentModal modalState="changeTreatment" />;
      case 'addPastTreatment':
        return <TreatmentModal modalState="addPastTreatment" />;
      case 'changePastTreatment':
        return <TreatmentModal modalState="changePastTreatment" />;
      case 'forgotPassword':
        return <ForgotPasswordModal />;
      case 'deleteUser':
        return <DeleteUserModal />;
      case 'changePassword':
        return <ChangePasswordModal />;
      default:
        return <></>;
    }
  };

  const frameHandler = modalState.includes('Exams')
    ? () => {}
    : () => setModalState('none');

  return (
    <Portal>
      {modalState !== 'none' && (
        <ModalArea>
          <Frame onClick={frameHandler} />
          <Container>
            <ErrorBoundary
              onClick={() => {
                setModalState('none');
              }}>
              <Suspense fallback={<></>}>{selectModal()}</Suspense>
            </ErrorBoundary>
          </Container>
        </ModalArea>
      )}
    </Portal>
  );
};

const ModalTitle: React.FC<Props & { color?: string }> = ({
  children,
  color = themeColor.green_500,
}: Props & { color?: string }) => {
  return (
    <TitleContainer $color={color}>
      <Label>{children}</Label>
    </TitleContainer>
  );
};
const ModalBody: React.FC<Props> = ({ children, ...rest }: Props) => {
  return <BodyContainer {...rest}>{children}</BodyContainer>;
};
const ModalContent: React.FC<Props> = ({ children, ...rest }: Props) => {
  return <ContentContainer {...rest}>{children}</ContentContainer>;
};
const ModalButtons: React.FC<Props> = ({ children, ...rest }: Props) => {
  return <ButtonContainer {...rest}>{children}</ButtonContainer>;
};

Modal.Title = ModalTitle;
Modal.Body = ModalBody;
Modal.Content = ModalContent;
Modal.Buttons = ModalButtons;

export default Modal;

const ModalArea = styled.div`
  ${flexStyle()()('center')()}
  color: ${themeColor.green_600};
  z-index: 5;
  position: relative;
`;

const Frame = styled.div`
  ${positionStyle('fixed')('0px')()()()}
  ${sizeStyle('100vw')('100vh')}
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
`;
const Container = styled.div`
  ${flexStyle('column')()()()}

  background: white;
  border: 1px solid ${themeColor.green_500};
  border-radius: 4px;

  ${positionStyle('fixed')('50%')()('50%')()}
  transform: translate(-50%, -50%);
`;

const TitleContainer = styled.div<{ $color: string }>`
  background: ${props => props.$color};
  ${sizeStyle()('72px')}
  ${flexStyle()()('center')('center')}
`;
const Label = styled.div`
  color: white;
  ${fontStyle('24px')(600)(32)}
  letter-spacing: -0.25px;
  text-align: center;
`;
const BodyContainer = styled.div`
  ${flexStyle('column')()()()}
  padding: 0px 40px;
`;
const ContentContainer = styled.div`
  ${flexStyle('column')()()()}
  padding: 40px 0px;
  max-height: 75vh;
  overflow-y: auto;
`;
const ButtonContainer = styled.div`
  ${flexStyle()('120px')('center')('center')}
  padding: 40px 0px;
`;
