import Footer from '@components/Footer';
import Header from '@components/Header';
import { flexStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';
import styled from 'styled-components';

const InnerLayout = ({
  color,
  children,
}: {
  color: 'white' | 'pale';
  children: JSX.Element;
}) => (
  <Container $color={color}>
    <Header hasSearchBox={false} />
    <Content>{children}</Content>
    <Footer />
  </Container>
);

const Container = styled.div<{ $color: 'white' | 'pale' }>`
  ${flexStyle('column')()()()}
  min-height: 100vh;
  position: relative;
  background-color: ${props =>
    props.$color === 'white' ? 'white' : themeColor.blue_bg};
`;
const Content = styled.div`
  flex: 1;
`;

export default InnerLayout;
