import styled from 'styled-components';
import ChartIcon from '@assets/icons/common/chart.svg';
import CardIcon from '@assets/icons/common/card.svg';
import { flexStyle, sizeStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';

const TabSwitcher = ({
  tab,
  tabList,
  setTab,
}: {
  tab: string;
  tabList: string[];
  setTab: (tab: string) => void;
}) => {
  const tabSwitcherSource: Record<string, { img: string; title: string }> = {
    chart: {
      img: ChartIcon,
      title: 'MYOPIA Chart',
    },
    data: {
      img: CardIcon,
      title: 'Full Data',
    },
    // calculator: {
    //   img: CardIcon,
    //   title: 'CRT Calculator',
    // },
    simulator: {
      img: CardIcon,
      title: 'Vision Simulator',
    },
  };

  return (
    <Wrapper>
      <Container>
        {tabList.map((ele, index) => {
          const position = (i: number) => {
            if (i === 0) return 'first';
            else if (i === tabList.length - 1) return 'last';
            else return 'middle';
          };

          return (
            <Item
              key={ele}
              $isSelected={tab === ele}
              $position={position(index)}
              onClick={() => setTab(ele)}>
              <Icon src={tabSwitcherSource[ele].img} alt={ele} />
              <Text className="typo-title-sm">
                {tabSwitcherSource[ele].title}
              </Text>
            </Item>
          );
        })}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${flexStyle('column')('10px')()('center')}
  ${sizeStyle('1400px')()}
  margin: 0 auto;
  position: relative;
  top: -21px;
`;

const Container = styled.div`
  display: flex;
  cursor: pointer;
`;
const Item = styled.div<{ $isSelected: boolean; $position: string }>`
  ${flexStyle()('6px')('center')('center')}
  padding: 8px 0px;
  ${sizeStyle('180px')()}
  background-color: ${props =>
    props.$isSelected ? themeColor.green_500 : 'white'};
  color: ${props => (props.$isSelected ? 'white' : themeColor.green_600)};
  img {
    -webkit-filter: ${props => props.$isSelected && 'brightness(0) invert(1)'};
    filter: ${props => props.$isSelected && 'brightness(0) invert(1)'};
  }
  border: ${props =>
    props.$isSelected ? 'none' : `1px solid ${themeColor.green_100}`};
  border-top-left-radius: ${props => props.$position === 'first' && '3px'};
  border-bottom-left-radius: ${props => props.$position === 'first' && '3px'};
  border-top-right-radius: ${props => props.$position === 'last' && '3px'};
  border-bottom-right-radius: ${props => props.$position === 'last' && '3px'};
  border-right: ${props => props.$position !== 'last' && '0px'};
  box-sizing: border-box;
  width: Fixed (180px);
`;
const Icon = styled.img`
  ${sizeStyle('16px')('16px')}
`;
const Text = styled.span``;

export default TabSwitcher;
