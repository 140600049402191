import errorBackground from '@assets/images/background.svg';

const ErrorPage = ({ children }: { children: JSX.Element }) => {
  // todo : 빌드 시 styled component 적용 안되는 문제 확인
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${errorBackground})`,
      }}>
      {children}
    </div>
  );
};

export default ErrorPage;
