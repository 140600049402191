import styled from 'styled-components';
import { themeColor } from './theme';
import { backEndField } from './literal';

export const saveSubmodalDescription = (id: string, code: number) => {
  const condition = [
    {
      condition: id === 'patientId' && code === 40900,
      text: <span>The patient ID you entered already exists.</span>,
    },
    {
      condition: id === 'birthDate' && code === 40002,
      text: (
        <span>
          The age listed in the existing exam records is
          <br /> outside the allowable range of 5 to 19 years
          <br /> old.
        </span>
      ),
    },
    {
      condition: id === 'examDate' && code === 40900,
      text: (
        <span>
          That date already has an exam record.
          <br />
          Remember, only one exam record can be
          <br />
          stored per date.
        </span>
      ),
    },
    {
      condition: id === 'examDate' && code === 40002,
      text: (
        <span>
          It{`'`}s out of the acceptable age range of 5 to 19
          <br />
          years old. Please check the date of birth and
          <br />
          the date of the exam.
        </span>
      ),
    },
    {
      condition: id === 'epiStatus' && code === 40001,
      text: (
        <span>
          Corneal Epi status of the initial exam data
          <br />
          should be Pre Ortho-K.
        </span>
      ),
    },
    {
      condition: true,
      text: <span>해당 없음</span>,
    },
  ];

  return (
    <p>
      Invalid data: <Red>{backEndField[id].text}</Red>
      <br />
      {condition.filter(ele => ele.condition === true)[0].text}
    </p>
  );
};

export const confirmSubmodalDescription = (id: string, subText?: string) => {
  const condition = [
    {
      condition: id === 'examsDelete',
      text: (
        <span>
          You’re going to <Red>permanently delete</Red>
          <br />
          the {subText} exam data. Are you sure?
        </span>
      ),
    },
    {
      condition: id === 'patientDelete',
      text: (
        <span>
          You’re going to <Red>permanently delete</Red>
          <br />
          the patient data. Are you sure?
        </span>
      ),
    },
    {
      condition: id === 'examsDiscard',
      text: (
        <span>
          {`You're going to discard the changes.`}
          <br />
          Are you sure?
        </span>
      ),
    },
    {
      condition: id === 'treatmentFailedStartDateEndDate',
      text: (
        <span>
          Invalid data: <Red>Start Date</Red> and/or <Red>End Date</Red>
          <br />
          Within the same group, treatment records for
          <br />
          each eye (OD and OS) should not have
          <br />
          overlapping treatment periods.
        </span>
      ),
    },
    {
      condition: id === 'signupEmailAlreadyRegistered',
      text: (
        <span>
          This email address is already asswociated
          <br />
          with an account.
        </span>
      ),
    },
    {
      condition: id === 'signupFailed',
      text: (
        <span>
          Please fill in all required fields to complete your
          <br />
          {`registration. Make sure you haven't missed`}
          <br />
          any information makred.
        </span>
      ),
    },
    {
      condition: id === 'signupSuccess',
      text: (
        <span>
          Your account has been successfully created.
          <br />
          You can now access our myopia management
          <br />
          platform. Would you like to explore our
          <br />
          services?
        </span>
      ),
    },
    {
      condition: id === 'signupFailedReasonUnknown',
      text: (
        <span>
          We encountered an unexpected error while
          <br />
          processing your registration. Please try again
          <br />
          later or contact our support team if the
          <br />
          problem persists.
        </span>
      ),
    },
    {
      condition: id === 'userSecessionSuccess',
      text: (
        <span>
          Your account has been successfully deleted.
          <br />
          {`We're sorry to see you go. Thank you for using`}
          <br />
          our service.
        </span>
      ),
    },
    {
      condition: id === 'userSecessionFailed',
      text: (
        <span>
          We encountered an error while trying to delete
          <br />
          your account. Please try again later or contact
          <br />
          our support team if the problem persists.
        </span>
      ),
    },
    {
      condition: id === 'signinFailed',
      text: <span>Incorrect email or password. Please try again.</span>,
    },
    {
      condition: id === 'signinFailedReasonUnknown',
      text: (
        <span>
          An error occurred while trying to log in. Please
          <br />
          try again later.
        </span>
      ),
    },
    {
      condition: id === 'emailFailed',
      text: (
        <span>
          {`We couldn't send the verification email. Please`}
          <br />
          try again in a few minutes. If the problem
          <br />
          persists, contact support.
        </span>
      ),
    },
    {
      condition: id === 'treatmentDelete',
      text: (
        <span>
          You’re going to <Red>permanently delete</Red>
          <br />
          the treatment data. Are you sure?
        </span>
      ),
    },
    {
      condition: id === 'forgotPassword',
      text: <span>Your password has been successfully reset.</span>,
    },
    {
      condition: true,
      text: <span>해당 없음</span>,
    },
  ];

  return <p>{condition.filter(ele => ele.condition === true)[0].text}</p>;
};

const Red = styled.span`
  color: ${themeColor.red};
`;
