import { flexStyle, sizeStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';
import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  data: { title: string; value: string }[];
  children: ReactNode;
  dataType: string;
};

const PatientBox: React.FC<Props> = ({ children, data, dataType }: Props) => {
  const contentValue = (value: string) => {
    return value !== '' ? value : 'not data';
  };

  return (
    <Container $dataType={dataType}>
      <Title className="typo-title-md">{children}</Title>
      {data.map(({ title, value }) => {
        return (
          <UserContent key={dataType + title}>
            <ContentTitle className="typo-body-sm">{title}</ContentTitle>
            <ContentSub
              data-testid={`patient-box-${dataType}-${title.replace(
                / /g,
                '-',
              )}`}
              className="typo-title-sm">
              {contentValue(value)}
            </ContentSub>
          </UserContent>
        );
      })}
    </Container>
  );
};

export default PatientBox;

const Container = styled.div<{ $dataType: string }>`
  background-color: ${themeColor.green_50};
  ${sizeStyle()('80px')}
  ${flexStyle()()()('center')}
  padding: 14px;
  box-sizing: border-box;
  grid-column: 1/4;
`;
const Title = styled.p`
  margin: 0px;
  ${sizeStyle('160px')()}
  color: ${themeColor.green_700};
`;
const UserContent = styled.div`
  ${flexStyle()('20px')()('center')}
  ${sizeStyle('240px')()}
`;
const ContentTitle = styled.p`
  margin: 0px;
  color: ${themeColor.green_400};
`;
const ContentSub = styled.p`
  margin: 0px;
  color: ${themeColor.green_700};
`;
