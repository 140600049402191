import { Response } from '@type/response';
import { AxiosError } from 'axios';

export const exceptionMessage = {
  BadRequest: 'BadRequest',
  EpiStatus: 'EpiStatus',
  Age: 'Age',
  Date: 'Date',
  NotFound: 'NotFound',
  Conflict: 'Conflict',
  UnAuthorized: 'UnAuthorized',
  Expired: 'Expired',
  Forbidden: 'Forbidden',
  Temporal: 'Temporal',
  Modal: 'Modal',
  NoUuid: 'NoUuid',
} as const;

type exceptionsName = keyof typeof exceptionMessage;

const exceptionMap: { code: number; name: exceptionsName }[] = [
  { code: 40000, name: 'BadRequest' },
  { code: 40001, name: 'EpiStatus' },
  { code: 40002, name: 'Age' },
  { code: 40004, name: 'Date' },
  { code: 40400, name: 'NotFound' },
  { code: 40900, name: 'Conflict' },
  { code: 40100, name: 'UnAuthorized' },
  { code: 40101, name: 'Expired' },
  { code: 40300, name: 'Forbidden' },
  { code: 403, name: 'Forbidden' },
  { code: 408, name: 'Temporal' },
];

const getExceptionName = (code: number | undefined): string | undefined => {
  const exception = exceptionMap.find(item => item.code === code);
  return exception?.name;
};

export const throwAxiosException = <T>(
  status: number | undefined,
  err: AxiosError,
  data: Response<T> | undefined,
) => {
  const exceptionName = getExceptionName(data?.code ?? status);

  if (exceptionName) {
    throw new Error(exceptionName);
  }

  throw err;
};
