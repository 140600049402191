import styled from 'styled-components';
import { PatientResponse } from '@feature/patient/type/patient';
import { PatientListBackground } from './utils';
import { flexStyle, sizeStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';

type Props = {
  patient: PatientResponse;
  patientId: string;
  name: 'same' | 'similar' | 'recently';
  selectedIndex: number;
  index: number;
  moveFn: (url: string) => void;
};

const PatientListItem = ({
  patient,
  patientId,
  name,
  selectedIndex,
  index,
  moveFn,
}: Props) => {
  const valueLen = patientId.length;
  const patientLen = patient.patient_identifier.length;
  const similarNum = patient.patient_identifier.substring(0, valueLen);
  const remainNum = patient.patient_identifier.substring(valueLen, patientLen);
  const backgroundColor = PatientListBackground(name, selectedIndex, index);

  return (
    <PatientList key={index} $backgroundColor={backgroundColor}>
      {name === 'similar' ? (
        <span>
          <PatientId $name={name}>{similarNum}</PatientId>
          {remainNum}
        </span>
      ) : (
        <span>
          <PatientId $name={name}>{patient.patient_identifier}</PatientId>
        </span>
      )}
      <span>{`${patient.first_name} ${patient.last_name}`}</span>
      <span>{patient.birth_date}</span>
      <span>
        <Buttons
          $name={name}
          onClick={() => {
            moveFn(`/patient/${patient.patient_uuid}`);
          }}>
          VIEW
        </Buttons>
        &nbsp;&nbsp;
        <Buttons
          $name={name}
          onClick={() => {
            moveFn(`/exam/${patient.patient_uuid}`);
          }}>
          + EXAM
        </Buttons>
      </span>
    </PatientList>
  );
};

export default PatientListItem;

const PatientList = styled.div<{ $backgroundColor: string }>`
  ${sizeStyle('100%')('40px')}
  ${flexStyle()()()('center')}
  padding: 0px 50px 0px 50px;
  box-sizing: border-box;
  color: ${themeColor.green_500};
  background: ${props => props.$backgroundColor};
  span {
    ${sizeStyle('25%')()}
    text-align: start;
  }
`;
const PatientId = styled.span<{ $name: string }>`
  background: ${props =>
    props.$name === 'same'
      ? '#4FB7D0'
      : props.$name === 'similar'
      ? '#FFEBB7'
      : 'none'};
  border-radius: ${props => (props.$name === 'same' ? '9999px' : '2px')};
  color: ${props => props.$name === 'same' && 'white'};
  padding: ${props => props.$name === 'same' && '4px 10px 4px 10px'};
`;
const Buttons = styled.button<{ $name: string }>`
  ${sizeStyle('60px')('20px')}
  font-size: 12px;
  border: 1.5px solid
    ${props => (props.$name === 'same' ? '#4FB7D0' : `${themeColor.green_400}`)};
  border-radius: 9999px;
  background: transparent;
  color: ${themeColor.green_400};
  cursor: pointer;
`;
