import { FieldPath, useForm } from 'react-hook-form';
import { useSetAtom } from 'jotai';
import useToast from '@hooks/useToast';
import { useMutation } from '@tanstack/react-query';
import RequiredIcon from '@assets/icons/common/required.svg';
import styled from 'styled-components';
import { z, ZodError } from 'zod';
import { ChangePassword, changePasswordSchema } from '../../type/users';
import { ModalStateAtom } from '@utils/modalAtom';
import { UserApi } from '@service/userApi';
import Modal from '@components/Modal';
import Input from '@components/Input';
import { flexStyle, fontStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';
import { exceptionMessage } from '@utils/exception';

const ChangePasswordModal = () => {
  const { register, getValues, watch } = useForm<ChangePassword>();
  const setModalState = useSetAtom(ModalStateAtom);
  const { pushToast } = useToast();

  const postMutation = useMutation({
    mutationKey: ['changePassword'],
    mutationFn: (data: ChangePassword) => UserApi.changePassword(data),
  });

  const validateItem = (item: FieldPath<ChangePassword>) => {
    if (!watch(item) || watch(item) === '') return false;

    try {
      if (
        item === 'confirm_password' &&
        watch('confirm_password') !== watch('new_password')
      ) {
        throw new ZodError([
          {
            path: ['confirm_password'],
            code: z.ZodIssueCode.custom,
            message: 'confirm password not same password',
          },
        ]);
      }
      changePasswordSchema.parse(getValues());
    } catch (err) {
      if (err instanceof ZodError) {
        const filtered = err.errors.filter(ele => ele.path.join('.') === item);
        if (filtered.length > 0) return true;
      }
      return false;
    }
  };

  const isDisabled = () => {
    const data = getValues();
    const dataArr = Object.entries(data) as [
      FieldPath<ChangePassword>,
      string,
    ][];
    if (dataArr.length <= 0) return true;
    return dataArr.some(([path, value]) => {
      return validateItem(path) || value === undefined || value === '';
    });
  };

  const cancel = () => {
    setModalState('none');
  };
  const changePassword = async () => {
    try {
      const data = getValues();
      await postMutation.mutateAsync(data);
      pushToast('you have successfully changed your password', 'success');
      setModalState('none');
    } catch (err) {
      if (err instanceof Error) {
        if (err.message === exceptionMessage.BadRequest)
          pushToast('Please check your password', 'error');
      } else {
        pushToast(
          'We’re experiencing a temporary issue. Please try again in a moment.',
          'error',
        );
      }
    }
  };

  return (
    <form>
      <Modal.Title>Change Password</Modal.Title>
      <Modal.Body>
        <Modal.Content style={{ paddingBottom: 0 }}>
          <Wrapper>
            <Label>
              <img
                src={RequiredIcon}
                alt="input required"
                height="6"
                width="5"
              />
              Current Password
            </Label>
            <Input
              type="password"
              error={validateItem('current_password')}
              {...register('current_password')}
            />
            <Label>
              <img
                src={RequiredIcon}
                alt="input required"
                height="6"
                width="5"
              />
              New Password
            </Label>
            <Input
              type="password"
              error={validateItem('new_password')}
              {...register('new_password')}
            />
            <Label>
              <img
                src={RequiredIcon}
                alt="input required"
                height="6"
                width="5"
              />
              Confirm Password
            </Label>
            <Input
              type="password"
              error={validateItem('confirm_password')}
              {...register('confirm_password')}
            />
          </Wrapper>
        </Modal.Content>
        <Modal.Buttons>
          <CustomButton $color="light" type="button" onClick={cancel}>
            Cancel
          </CustomButton>
          <CustomButton
            $color="dark"
            type="button"
            onClick={changePassword}
            disabled={isDisabled()}>
            Change Password
          </CustomButton>
        </Modal.Buttons>
      </Modal.Body>
    </form>
  );
};

const Label = styled.div`
  ${flexStyle()('4px')()('center')};
  ${fontStyle('12px')(400)(20)};
  letter-spacing: -0.25px;
  color: ${themeColor.green_700};
`;

const Wrapper = styled.div`
  ${flexStyle('column')('10px')()()};
`;

const CustomButton = styled.button<{ $color: 'dark' | 'light' }>`
  ${fontStyle('16px')(500)(24)};
  letter-spacing: -0.25px;
  ${flexStyle()('8px')('center')('center')};
  box-sizing: border-box;
  padding: 10px;

  width: 180px;
  height: 40px;

  background: ${props =>
    props.$color === 'dark' ? themeColor.green_600 : 'white'};
  color: ${props => (props.$color === 'dark' ? 'white' : themeColor.green_600)};
  border: 2px solid ${themeColor.green_600};
  border-radius: 9999px;

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: default;
    background: ${themeColor.green_200};
    border-color: ${themeColor.green_200};
  }
`;

export default ChangePasswordModal;
