import styled from 'styled-components';
import visuworks_logo from '@assets/images/logo_visuworks_white.svg';
import { flexStyle, sizeStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';

const Footer = () => {
  return (
    <Container>
      <Content>
        <LogoImg src={visuworks_logo} alt="logo" />
        <Text>
          <TopText className="typo-label-sm">
            <SubText>
              ADD. #1101 Tongyeong Building, 405 Gangnam-daero, Seocho-gu,
              Seoul, Republic of Korea
            </SubText>
            <SubText>Email visuworks2019@gmail.com</SubText>
          </TopText>
          <BottomText className="typo-label-sm">
            <SubText>
              Copyrights 2023. VISUWORKS Inc. all rights reserved
            </SubText>
          </BottomText>
        </Text>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${themeColor.green_500};
  color: white;
  ${sizeStyle('100%')('6rem')};
  ${flexStyle()()('center')('center')};
  min-width: 1400px;
`;
const Content = styled.div`
  ${flexStyle()()()('flex-start')}
  ${sizeStyle('1400px')()}
  
  padding-top: 22px;
  box-sizing: border-box;
`;
const LogoImg = styled.img`
  width: 80px;
  height: 27px;
`;
const Text = styled.div`
  margin-left: 40px;
  ${sizeStyle('100%')()}
`;
const SubText = styled.p`
  margin-top: 0px;
`;
const TopText = styled.div`
  ${flexStyle()()('space-between')()}
  ${sizeStyle('50%')()}
  color: ${themeColor.green_50};
`;
const BottomText = styled.div`
  color: ${themeColor.green_200};
`;

export default Footer;
