import { isPassword } from '@utils/general';
import { parsePhoneNumber } from 'libphonenumber-js/min';
import { z } from 'zod';

export type Account = 'INDIVIDUAL' | 'INSTITUTION';
export type CheckAgreement = 'terms' | 'private';

const passwordSchema = z.string().refine(
  val => {
    return isPassword(val);
  },
  { message: '비밀번호 규칙 위반' },
);

const doctorSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
});
const institutionSchema = z.object({
  name: z.string(),
  contact_number: z.string().refine(arg => {
    if (arg && arg !== '') {
      try {
        const parsed = parsePhoneNumber(arg);
        return parsed.isValid();
      } catch (err) {
        return false;
      }
    }
    return true;
  }),
  address_1: z.string(),
  address_2: z.string().optional(),
  zip_code: z.string().optional(),
  city: z.string(),
});
const personalSchema = z.object({
  account_type: z.enum(['INDIVIDUAL', 'INSTITUTION']),
  email: z.string().email(),
  password: passwordSchema,
  first_name: z.string(),
  last_name: z.string(),
  contact_number: z.string().refine(arg => {
    if (arg && arg !== '') {
      try {
        const parsed = parsePhoneNumber(arg);
        return parsed.isValid();
      } catch (err) {
        return false;
      }
    }
    return true;
  }),
  doctor_license: z.instanceof(File).optional(),
  verification_code: z.string(),
});
const personalFormSchema = personalSchema.extend({
  confirm_password: z.string(),
});
const userPersonalSchema = personalSchema
  .omit({
    password: true,
    verification_code: true,
    doctor_license: true,
  })
  .extend({
    user_uuid: z.string(),
  });

const userSchema = userPersonalSchema.extend({
  institutions: institutionSchema.array(),
  doctors: doctorSchema.array().optional(),
});

const getUserSchema = userSchema.extend({
  institutions: institutionSchema.array(),
  doctors: doctorSchema.extend({ doctor_uuid: z.string() }).array().optional(),
});

export const userFormschema = userSchema.omit({
  account_type: true,
  user_uuid: true,
});

const patchUserSchema = userFormschema.omit({
  email: true,
});

const signupSchema = z.object({
  doctor_license: z.instanceof(File).optional(),
  user: personalSchema.omit({ doctor_license: true }).extend({
    institutions: institutionSchema.array(),
    doctors: doctorSchema.array().optional(),
  }),
});

export const signupFormSchema = personalFormSchema.extend({
  institutions: institutionSchema.array(),
  doctors: doctorSchema.array().optional(),
});

const signinSchema = z.object({
  email: z.string().email(),
  password: passwordSchema,
});

const emailSchema = z.object({
  email: z.string().email(),
});
const passwordObjectSchema = z.object({
  password: passwordSchema,
});

const emailConfirmSchema = emailSchema.extend({
  verification_code: z.string(),
});
export const passwordResetConfirm = z.object({
  password: passwordSchema,
  confirm_password: z.string(),
});

export type SignupType = z.infer<typeof signupSchema>;
export type SignupFormType = z.infer<typeof signupFormSchema>;

export type SigninType = z.infer<typeof signinSchema>;

export type GetUser = z.infer<typeof getUserSchema>;
export type UserForm = z.infer<typeof userFormschema>;
export type PatchUser = z.infer<typeof patchUserSchema>;

export type Email = z.infer<typeof emailSchema>;
export type EmailConfirm = z.infer<typeof emailConfirmSchema>;
export type PasswordResetConfirm = z.infer<typeof passwordResetConfirm>;
export type Password = z.infer<typeof passwordObjectSchema>;
export type Doctors = z.infer<typeof doctorSchema>[];
export type Doctor = z.infer<typeof doctorSchema>;
