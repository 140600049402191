import { isPassword } from '@utils/general';
import { z } from 'zod';

const passwordSchema = z.string().refine(
  val => {
    return isPassword(val);
  },
  { message: '비밀번호 규칙 위반' },
);

export const changePasswordSchema = z.object({
  current_password: z.string(),
  new_password: passwordSchema,
  confirm_password: z.string(),
});

export type ChangePassword = z.infer<typeof changePasswordSchema>;
