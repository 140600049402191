import { Component, ErrorInfo, ReactNode } from 'react';
import styled from 'styled-components';
import ErrorText from './ErrorText';
import ErrorComponent from './ErrorComponent';
import Button from '@components/Button';
import { themeColor } from '@utils/theme';
import { flexStyle, fontStyle, sizeStyle } from '@utils/commonStyle';
import { exceptionMessage } from '@utils/exception';

interface Props {
  onClick?: () => void;
  children?: ReactNode;
  width?: string;
  height?: string;
}

interface State {
  render: boolean; // 여기서 렌더링 할 건가요?
  handle: boolean; // 처리해야 하는 에러인가요?
  component: React.ReactNode; // 보여줄 UI
  error?: Error; // 상위 에러 바운더리로 전파를 위한 에러
  onClick?: () => void; //ModalError 케이스에서만 사용해주세요..
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    render: false,
    handle: false,
    component: <div></div>,
  };
  constructor(props: Props) {
    super(props);
    if (props.onClick !== undefined) this.state.onClick = props.onClick;
  }

  public static getDerivedStateFromError(err: Error): State {
    if (err.message === exceptionMessage.NoUuid) {
      return {
        render: true,
        handle: true,
        component: <div>NoUuidError</div>,
        error: err,
      };
    } else if (err.message === exceptionMessage.Modal) {
      return {
        render: true,
        handle: true,
        component: (
          <div>
            <ModalTitle>Modal Error</ModalTitle>
            <div style={{ padding: '40px 80px 60px 80px' }}>
              <ErrorText type="ERROR" />
            </div>
          </div>
        ),
        error: err,
      };
    } else {
      return {
        render: true,
        handle: true,
        component: (
          <div style={{ padding: '200px 0px' }}>
            <ErrorComponent type="ERROR" />
          </div>
        ),
        error: err,
      };
    }

    //표시할 UI를 기준으로 해서 예외를 만들고 거기에 해당하는 컴포넌트 매칭하기

    // NoUuidError -> 404 환자번호를 찾을 수 없거나 접근 권한이 없음 화면
    // TemporalError -> 일시적으로 안됨과 get 요청을 다시 할 수 있는 버튼이 잇습니다
    // ModalError -> 일시적으로 안됨과 모달 닫기 버튼이 있음
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    const { onClick, width, height, children } = this.props;
    const { render, component } = this.state;

    if (render) {
      {
        if (onClick) {
          return (
            <ModalContainer>
              {component}
              <Button
                size="medium"
                color={themeColor.green_400}
                fontColor="light"
                border="none"
                style={{ width: '140px', height: '40px' }}
                onClick={onClick}>
                Close
              </Button>
            </ModalContainer>
          );
        } else {
          if (width && height)
            return (
              <Container $width={width} $height={height}>
                {component}
              </Container>
            );
          return component;
        }
      }
    }
    return children;
  }
}

const Container = styled.div<{ $width: string; $height: string }>`
  ${flexStyle('column')()('center')('center')};
  ${props => sizeStyle(props.$width)(props.$height)}
`;

const ModalContainer = styled.div`
  ${flexStyle('column')('10px')('center')('center')};
  padding-bottom: 40px;
`;

const ModalTitle = styled.div`
  background: ${themeColor.green_500};
  ${sizeStyle()('72px')}
  ${flexStyle()()('center')('center')}

  color: white;
  ${fontStyle('24px')(600)(32)}
  letter-spacing: -0.25px;
  text-align: center;
`;

export default ErrorBoundary;
