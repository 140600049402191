import styled from 'styled-components';
import { PatientResponse } from '../../type/patient';
import { sizeStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';
import SamePatientList from './SamePatientList';
import AddPatientList from './AddPatientList';
import SimilarPatientList from './SimilarPatientList';
import RecentlyPatientList from './RecentlyPatientList';

type Props = {
  patientData: PatientResponse[];
  recentData: PatientResponse[] | undefined;
  patientId: string;
  page: 'main' | 'header';
  selectedIndex: number;
  moveFn: (url: string) => void;
  addFn: () => void;
};

const SearchComponent = ({
  patientData,
  recentData,
  patientId,
  page,
  selectedIndex,
  moveFn,
  addFn,
}: Props) => {
  return (
    <Component
      onMouseDown={e => {
        e.preventDefault();
      }}>
      <Content $page={page}>
        <Hr />
        {patientData.some(
          num =>
            num.patient_identifier.toUpperCase() === patientId.toUpperCase(),
        ) ? (
          <SamePatientList
            patientData={patientData}
            patientId={patientId}
            selectedIndex={selectedIndex}
            moveFn={moveFn}
          />
        ) : (
          <AddPatientList
            selectedIndex={selectedIndex}
            patientId={patientId}
            addFn={addFn}
          />
        )}
        <SimilarPatientList
          patientData={patientData}
          patientId={patientId}
          selectedIndex={selectedIndex}
          moveFn={moveFn}
        />
        {recentData && (
          <RecentlyPatientList
            recentData={recentData}
            patientId={patientId}
            selectedIndex={selectedIndex - patientData.length}
            moveFn={moveFn}
          />
        )}
      </Content>
    </Component>
  );
};

export default SearchComponent;

const Component = styled.div`
  ${sizeStyle('660px')('100%')}
  margin: 0 auto;
`;
const Content = styled.div<{ $page: string }>`
  ${sizeStyle('658px')()}
  background: white;
  border-radius: ${props =>
    props.$page === 'main' ? '0px 0px 25px 25px' : '0px 0px 10px 10px'};
  border: 1px solid ${themeColor.green_200};
  border-top: none;
  padding-bottom: 20px;
  position: absolute;
  z-index: 1;
`;
const Hr = styled.div`
  ${sizeStyle('94%')()}
  border-bottom: 1.5px solid ${themeColor.green_200};
  margin: 0 auto;
`;
