import axios from 'axios';
import { statusCodeConfig } from './constants';

const devMode = process.env.NODE_ENV;
const vite = import.meta.env.VITE_BASE_URL;

const baseUrl = devMode === 'mock' ? '' : vite;

export const instance = axios.create({
  baseURL: baseUrl,
});

instance.interceptors.request.use(
  config => {
    config.headers['Session-Id'] = localStorage.getItem('Session-Id');
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const pathName = location.pathname;

    // 40100 인증 -> 세션이 만료되었으니 맨 처음 로그인 페이지로 이동
    // 40300 인가 -> 권한이 없으니 메인 페이지로 이동
    for (const config of statusCodeConfig) {
      const { code, specificUrl, excludePaths, redirectTo } = config;

      if (
        error.response.status === code &&
        (!specificUrl || error.config.url === specificUrl) &&
        !excludePaths.some(path => pathName.includes(path))
      ) {
        location.replace(redirectTo);
        break;
      }
    }

    return Promise.reject(error);
  },
);
