import styled from 'styled-components';
import { ReactNode } from 'react';
import { sizeStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';
import { day } from './utils';
import { DateBarToDot } from '@utils/date';

type Props = {
  children: ReactNode;
  date: string;
};

const PatientInfoDate = ({ children, date }: Props) => {
  const isLastDate = (lastDate: string) => {
    return lastDate !== 'null' && lastDate !== undefined ? lastDate : '-';
  };

  return (
    <Container>
      <Title className="typo-body-lg">{children}</Title>
      <LastDate className="typo-display-sm">
        {isLastDate(DateBarToDot(date))}
      </LastDate>
      <LastDateDay className="typo-headline-md">
        {isLastDate(day(date))}
      </LastDateDay>
    </Container>
  );
};

export default PatientInfoDate;

const Container = styled.div`
  ${sizeStyle('18%')('100%')}
`;
const Title = styled.p`
  color: ${themeColor.green_400};
  margin: 0px;
`;
const LastDate = styled.p`
  color: ${themeColor.navy};
  margin: 20px 0px 0px 0px;
`;
const LastDateDay = styled.p`
  color: ${themeColor.green_600};
  margin-top: 0px;
`;
