import { sizeStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color: string;
  fontColor?: 'light' | 'dark';
  size?: 'small' | 'medium' | 'large';
  border?: 'solid' | 'none';
  children: ReactNode;
}

const buttonWidth = {
  small: '6rem',
  medium: '200px',
  large: '18rem',
};

const Button: React.FC<Props> = ({
  color,
  fontColor = 'light',
  size = 'small',
  border = 'none',
  children,
  ...rest
}: Props) => {
  return (
    <ButtonContainer
      $color={color}
      $fontColor={fontColor}
      $size={size}
      $border={border}
      {...rest}>
      {children}
    </ButtonContainer>
  );
};
export default Button;

const ButtonContainer = styled.button<{
  $color: string;
  $fontColor: 'light' | 'dark';
  $size: 'small' | 'medium' | 'large';
  $border: 'solid' | 'none';
}>`
  ${props =>
    sizeStyle(buttonWidth[props.$size])(
      props.$size === 'small' ? '1.5rem' : '3rem',
    )}
  border: ${props =>
    props.$border !== 'none' ? `1px solid ${themeColor.green_600}` : 'none'};
  background: ${props => props.$color};
  border-radius: 9999px;
  color: ${props =>
    props.$fontColor === 'light' ? 'white' : `${themeColor.green_600}`};

  font-size: ${props => (props.$size === 'small' ? '12px' : '16px')};
  text-align: center;
  cursor: pointer;
  &:disabled {
    background-color: ${themeColor.gray};
    cursor: default;
  }
`;
