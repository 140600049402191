import danger_circle from '@assets/icons/common/danger_circle.svg';
import tick_square from '@assets/icons/common/tick_square.svg';
import close_square from '@assets/icons/common/close_square.svg';
import send from '@assets/icons/common/send.svg';
import {
  check_blue,
  check_blue2,
  check_gray,
  check_green,
  check_green_600,
  check_orange,
  check_purple,
  check_red,
} from '@assets/icons/check';
import {
  growthCurve_baseline,
  growthCurve_custom_1,
  growthCurve_custom_2,
  growthCurve_custom_3,
  growthCurve_initial,
  history_atropine,
  history_baseline,
  history_rlrl,
  history_spectacles,
} from '@assets/icons/chart';
import { themeColor } from './theme';
import { ExamsResponseOneEye } from '@feature/exam/type/exams';

export const fundusNested = {
  MR: ['MR_Sph', 'MR_Cyl', 'MR_Axis'],
  AR: ['AR_Sph', 'AR_Cyl', 'AR_Axis'],
  keratometry: ['K1', 'K2', 'K1_Axis'],
  AS: ['AS_AL', 'AS_ACD'],
};

export const fundusSubtitle: Record<string, string> = {
  MR: 'Manifest Refraction',
  AR: 'Autorefraction',
  keratometry: 'Keratometry',
  AS: 'AL-Scan',
};

export const fundusExam = {
  MR: ['MR_Sph', 'MR_Cyl', 'MR_Axis', 'MR_SE'],
  AR_K: ['AR_Sph', 'AR_Cyl', 'AR_Axis', 'AR_SE', 'K1', 'K2', 'K1_Axis'],
  AS_topo: ['AS_AL', 'delta_AS_AL', 'AS_ACD'],
};

export const fundusExamTitle: Record<string, string> = {
  MR: 'Manifest Refraction',
  AR_K: 'Auto Refraction & Keratometry',
  AS_topo: 'Biometry',
};

export const toastStyles = {
  default: {
    src: danger_circle,
    color: themeColor.green_500,
  },
  success: {
    src: tick_square,
    color: themeColor.green,
  },
  error: {
    src: close_square,
    color: themeColor.red,
  },
  info: { src: send, color: themeColor.blue2 },
  warning: {
    src: danger_circle,
    color: themeColor.yellow,
  },
};

export const fundusHeader: (keyof ExamsResponseOneEye)[] = [
  'Epi_Status',
  'MR_Sph',
  'MR_Cyl',
  'MR_Axis',
  'MR_SE',
  'AR_Sph',
  'AR_Cyl',
  'AR_Axis',
  'AR_SE',
  'K1',
  'K2',
  'K1_Axis',
  'AS_AL',
  'delta_AS_AL',
  'AS_ACD',
];

export const noCalculator = [
  'MR_Sph',
  'MR_Cyl',
  'AR_Sph',
  'AR_Cyl',
  'K1',
  'K2',
  'AS_AL',
  'AS_ACD',
];

export const backEndField: Record<string, { key: string; text: string }> = {
  patientId: {
    key: 'patient_identifier',
    text: 'Patient ID',
  },
  birthDate: {
    key: 'birth_date',
    text: 'Birth Date',
  },
  examDate: {
    key: 'exam_date',
    text: 'Exam Date',
  },
  epiStatus: {
    key: 'Epi_Status',
    text: 'Corneal Epi. Status',
  },
};

export const checkSrc = {
  red: check_red,
  blue: check_blue,
  blue2: check_blue2,
  purple: check_purple,
  orange: check_orange,
  green: check_green,
  gray: check_gray,
  green_600: check_green_600,
};

export const legendSrc: Record<string, string> = {
  growthcurve_baseline: growthCurve_baseline,
  growthcurve_custom_1: growthCurve_custom_1,
  growthcurve_custom_2: growthCurve_custom_2,
  growthcurve_custom_3: growthCurve_custom_3,
  growthcurve_initial: growthCurve_initial,
  history_atropine: history_atropine,
  history_baseline: history_baseline,
  history_rlrl: history_rlrl,
  history_spectacles: history_spectacles,
};
