import Footer from '@components/Footer';
import Header from '@components/Header';
import TabSwitcher from '@components/TabSwitcher';
import PatientInfo from '@feature/patient/components/patientInfo/PatientInfo';
import { flexStyle, fontStyle } from '@utils/commonStyle';
import { themeColor } from '@utils/theme';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const PatientLayout = ({ children }: { children: JSX.Element }) => {
  const { patient_uuid } = useParams();
  // [refactor] url의 역할이 단일적이지 않고 location.pathname을 state로 만들어서 쓰는 부분도 마음에 안듬..
  const location = useLocation();
  const url: Record<string, string> = useMemo(() => {
    return {
      data: 'exam',
      chart: 'patient',
      // calculator: 'crt',
      exam: 'data',
      patient: 'chart',
      // crt: 'calculator',
    };
  }, []);

  const [tab, setTab] = useState<string>(url[location.pathname.split('/')[1]]);
  const navigate = useNavigate();

  const handler = (tab: string) => {
    setTab(tab);
    navigate(`/${url[tab]}/${patient_uuid}`);
  };

  useEffect(() => {
    setTab(url[location.pathname.split('/')[1]]);
  }, [url, location.pathname]);

  return (
    <>
      <Container $color="white">
        <Header hasSearchBox={true} />
        <PatientInfo />
        <Switched>
          <TabSwitcher
            tab={tab}
            // tabList={['chart', 'data', 'calculator']}
            tabList={['chart', 'data']}
            setTab={handler}
          />
          <SubText>
            MyOPIA Chart is automatically updated whenever you add or edit data.
          </SubText>
        </Switched>
        <ContentColored>{children}</ContentColored>
        <Footer />
      </Container>
    </>
  );
};

const Container = styled.div<{ $color: 'white' | 'pale' }>`
  ${flexStyle('column')()()()}
  min-height: 100vh;
  position: relative;
  background-color: ${props =>
    props.$color === 'white' ? 'white' : themeColor.blue_bg};
`;
const ContentColored = styled.div`
  flex: 1;
  background: ${themeColor.blue_bg};
  min-width: 1400px;
  min-height: 100vh;
`;
const SubText = styled.div`
  ${fontStyle('14px')(300)(24)}
  letter-spacing: -0.25px;
  text-align: center;
  margin: 0 auto;
  width: 1400px;
  color: ${themeColor.green_400};
`;

const Switched = styled.div`
  background: ${themeColor.blue_bg};
  min-width: 1400px;
  border-top: 1px solid ${themeColor.green_100};
`;

export default PatientLayout;
