import useToast from '@hooks/useToast';
import Portal from './Portal';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { flexStyle, positionStyle, sizeStyle } from '@utils/commonStyle';
import { toastStyles } from '@utils/literal';

/**
 * App.tsx에서만 사용하고, 일반 컴포넌트 내부에는 배치하지 말아주세요!
 * 토스트 필요할 때는 useToast의 pushToast 함수를 사용합니다.
 */
const Toast = () => {
  const { toastList, toastLength } = useToast();
  return (
    <Portal>
      <AnimatePresence mode="wait">
        {toastLength() > 0 && (
          <Container>
            {toastList.map(ele => {
              return (
                <motion.div
                  key={ele.key}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.25 }}
                  layout>
                  <ToastItem>
                    <Icon
                      data-testid="toast-color"
                      $color={toastStyles[ele.status].color}>
                      <img
                        src={toastStyles[ele.status].src}
                        width="24"
                        height="24"
                        alt="toast icon"
                      />
                    </Icon>
                    <Message
                      className="typo-body-sm"
                      $color={toastStyles[ele.status].color}>
                      {ele.message}
                    </Message>
                  </ToastItem>
                </motion.div>
              );
            })}
          </Container>
        )}
      </AnimatePresence>
    </Portal>
  );
};

const Container = styled.div`
  ${positionStyle('fixed')('3rem')()()()}
  left: calc(50% - 230px);
  ${flexStyle('column')('8px')()()}
  z-index: 15;
  position: fixed;
`;
const ToastItem = styled.div`
  background-color: white;
  ${sizeStyle('460px')()}
  border: 0px solid black;
  border-radius: 0px 0px 6px 6px;
  box-shadow: 0px 4px 8px 0px #0025481a;
  display: flex;
`;
const Icon = styled.div<{ $color: string }>`
  background-color: ${props => props.$color};
  padding: 8px;
  ${flexStyle()()('center')('center')}
  border-radius: 0px 0px 0px 6px;
`;
const Message = styled.div<{ $color: string }>`
  color: ${props => props.$color};
  align-self: center;
  text-align: center;
  ${sizeStyle('100%')()}
`;

export default Toast;
