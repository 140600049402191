import { PatientResponse } from '@feature/patient/type/patient';
import { DateBarToDot } from '@utils/date';

export const doctorNameFormatter = (
  firstName: string | undefined,
  lastName: string | undefined,
) => {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  else return '';
};

export const sexFormatter = (value: 'FEMALE' | 'MALE') => {
  return value === 'FEMALE' ? 'Female' : 'Male';
};

export const extractedData = (
  data: PatientResponse,
  key: 'patient' | 'doctor',
) => {
  if (key === 'patient') {
    return [
      { title: 'Patient ID', value: data.patient_identifier },
      { title: 'Name', value: `${data.first_name} ${data.last_name}` },
      { title: 'Birth date', value: DateBarToDot(data.birth_date) },
      { title: 'Sex', value: sexFormatter(data.sex) },
    ];
  } else {
    return [
      {
        title: 'Name',
        value: doctorNameFormatter(
          data.doctor?.first_name,
          data.doctor?.last_name,
        ),
      },
    ];
  }
};

const week = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const day = (date: string) => week[new Date(date).getDay()];
